import { INodeProps, Module, Node } from "@msdyn365-commerce-modules/utilities";

import * as React from "react";
import { IProductComponentViewProps } from "@msdyn365-commerce-modules/product-collection/src/modules/product-collection/./product-collection";
import { ProductContext } from "../context/productContext";
import ProductComponentPremium from "../views/components/product.component.premium";
const ProductCollectionView: React.FC<any> = (props) => {
  const {
    heading,
    ProductCollectionContainer,
    products,
    SingleSlideCarouselComponentProps,
    GridComponentProps,
    isCarousel,
    context,
    data,
    resources,
  } = props;

  let productTypesId: any = [];

  //const [setProductType] = React.useState<any>([]);
  // const [premiumUserState, setPremiumUserState] = React.useState<any>();

  const productsCopy = products;
  React.useEffect(() => {
    if (products) {
      const productIds = products.map((item: any) => {
        return (
          item.productComponent.props.data.product &&
          item?.productComponent?.props?.data?.product?.RecordId
        );
      });

      callMe(productIds, productsCopy);
    }

    async function callMe(records: any, productsCopy: any) {
      for (var i = 0; i < records.length; i++) {
        productsCopy.map((item: any, index: any) => {
          if (
            item.productComponent.props.data.product?.RecordId === records[i]
          ) {
            item.productComponent.props.data.product["Premium"] =
              productTypesId[index];
          }
        });
        if (i == records.length - 1) {
          setTimeout(() => {
            //setProductType(productTypesId);
          }, 100);
        }
      }
    }
  }, []);

  if (products && context.request.user.isAuthenticated) {
    if (products) {
      return (
        <ProductContext.Provider value={{ data, resources }}>
          <Module {...ProductCollectionContainer}>
            {products.length > 0 ? heading : null}
            {isCarousel
              ? _renderCarousel(
                  SingleSlideCarouselComponentProps,
                  products,
                  context,
                  data,
                  resources,
                  props?.data?.customerInformation?.result?.CurrencyCode
                )
              : _renderGrid(
                  GridComponentProps,
                  products,
                  context,
                  data,
                  resources.currentPriceText,
                  props?.data?.customerInformation?.result?.CurrencyCode
                )}
          </Module>
        </ProductContext.Provider>
      );
    } else {
      return (
        <ProductContext.Provider value={{ data, resources }}>
          <Module {...ProductCollectionContainer}>
            {products.length > 0 ? heading : null}
            {isCarousel
              ? _renderCarousel(
                  SingleSlideCarouselComponentProps,
                  products,
                  context,
                  data,
                  resources,
                  props?.data?.customerInformation?.result?.CurrencyCode
                )
              : _renderGrid(
                  GridComponentProps,
                  products,
                  context,
                  data,
                  resources.currentPriceText,
                  props?.data?.customerInformation?.result?.CurrencyCode
                )}
          </Module>
        </ProductContext.Provider>
      );
    }
  }
  props.context.telemetry.error(
    "Product collection content is empty, module wont render."
  );
  return null;
};

const _renderCarousel = (
  carouselContainer: INodeProps,
  items: IProductComponentViewProps[],
  context: any,
  data: any,
  resources: any,
  currencyCode: any
): JSX.Element => {
  return (
    <Node {...carouselContainer}>
      {items &&
        items.map((item, index) =>
          _renderProduct(index, item, context, data, resources, currencyCode)
        )}
    </Node>
  );
};

const _renderGrid = (
  gridContainer: INodeProps,
  items: IProductComponentViewProps[],
  context: any,
  data: any,
  resources: any,
  currencyCode: any
): JSX.Element => {
  return (
    <Node {...gridContainer}>
      {items &&
        items.map((item, index) =>
          _renderProduct(index, item, context, data, resources, currencyCode)
        )}
    </Node>
  );
};

const _renderProduct = (
  index: any,
  product: any,
  context: any,
  data: any,
  resources: any,
  currencyCode: any
): JSX.Element => {
  return (
    <Node {...product?.ProductContainer}>
      <ProductComponentPremium
        {...product?.productComponent?.props}
        {...resources}
        currencyDynmaicCode={currencyCode}
      />
    </Node>
  );
};

export default ProductCollectionView;
