import {getProductPageUrlSync} from "@msdyn365-commerce-modules/retail-actions";
import {
  format,
  getPayloadObject,
  getTelemetryAttributes,
  ITelemetryContent,
  onTelemetryClick,
} from "@msdyn365-commerce-modules/utilities";
import {
  IComponent,
  IComponentProps,
  ICoreContext,
  IGridSettings,
  IImageData,
  IImageSettings,
  Image,
  msdyn365Commerce,
} from "@msdyn365-commerce/core";
import {ProductSearchResult} from "@msdyn365-commerce/retail-proxy";
import React from "react";
import {RatingComponent} from "@msdyn365-commerce/components";
//import {getAttributeValuesAsync} from "@msdyn365-commerce/retail-proxy/dist/DataActions/ProductsDataActions.g";

export interface IProductComponentProps
  extends IComponentProps<{product?: ProductSearchResult}> {
  className?: string;
  imageSettings?: IImageSettings;
  savingsText?: string;
  freePriceText?: string;
  originalPriceText?: string;
  currentPriceText?: string;
  ratingAriaLabel?: string;
  allowBack?: boolean;
  telemetryContent?: ITelemetryContent;
  quickViewButton?: React.ReactNode;
}

export interface IProductComponent extends IComponent<IProductComponentProps> {}

const PriceComponentActions = {};

const ProductCard: React.FC<any> = (props: any) => {
  const {
    data,
    context,
    imageSettings,
    ratingAriaLabel,
    allowBack,
    typeName,
    id,
    telemetryContent,
    quickViewButton,
  } = props;
  const product = data.product;

  if (!product) {
    return null;
  }

  let productUrl = getProductPageUrlSync(
    product.Name || "",
    product.RecordId,
    context && context.actionContext,
    undefined,
  );
  if (allowBack) {
    productUrl = updateProductUrl(productUrl, context);
  }

  // Construct telemetry attribute to render
  const payLoad = getPayloadObject(
    "click",
    telemetryContent!,
    "",
    product.RecordId.toString(),
  );

  const attribute = getTelemetryAttributes(telemetryContent!, payLoad);

  if (
    typeof window !== "undefined" &&
    typeof document !== "undefined" &&
    typeof localStorage !== "undefined"
  ) {
    return (
      <>
        {quickViewButton && renderQuickView(quickViewButton, product.RecordId)}
        <a
          href={productUrl}
          onClick={onTelemetryClick(telemetryContent!, payLoad, product.Name!)}
          aria-label={renderLabel(
            product.Name,
            context.cultureFormatter.formatCurrency(product.Price),
            product.AverageRating,
            ratingAriaLabel,
          )}
          className="msc-product"
          {...attribute}
        >
          <div className="msc-product__image">
            {renderProductPlacementImage(
              imageSettings,
              context.request.gridSettings,
              product.PrimaryImageUrl,
              product.Name,
            )}
          </div>
          <div className="msc-product__details">
            <h4 className="msc-product__title">{product.Name}</h4>
            <a
              href={productUrl}
              onClick={onTelemetryClick(
                telemetryContent!,
                payLoad,
                product.Name!,
              )}
              className="msc-product msc-product__btn msc-btn"
              {...attribute}
            >
              {props.viewDetails}
            </a>

            {renderDescription(product.Description)}
            {!context.app.config.hideRating &&
              renderRating(
                context,
                typeName,
                id,
                product.AverageRating,
                product.TotalRatings,
                ratingAriaLabel,
              )}
          </div>
        </a>
      </>
    );
  } else {
    return <></>;
  }
};

function renderLabel(
  name?: string,
  price?: string,
  rating?: number,
  ratingAriaLabel?: string,
): string {
  name = name || "";
  price = price || "";
  return `${name} ${price} ${getRatingAriaLabel(rating, ratingAriaLabel)}`;
}

function renderDescription(description?: string): JSX.Element | null {
  return <p className="msc-product__text">{description}</p>;
}

function renderQuickView(
  quickview: React.ReactNode,
  item?: number,
): JSX.Element | undefined {
  if (quickview === null) {
    return undefined;
  }
  return React.cloneElement(quickview as React.ReactElement, {
    selectedProductId: item,
  });
}

function getRatingAriaLabel(rating?: number, ratingAriaLabel?: string): string {
  if (rating && ratingAriaLabel) {
    const roundedRating = rating.toFixed(2);
    return format(ratingAriaLabel || "", roundedRating, "5");
  }
  return "";
}

function updateProductUrl(productUrl: string, context: ICoreContext): string {
  const srcUrl = new URL(productUrl, context.request.apiSettings.baseUrl);
  const queryString = "back=true";
  if (srcUrl.search) {
    srcUrl.search += `&${queryString}`;
  } else {
    srcUrl.search += queryString;
  }

  const updatedUrl = new URL(srcUrl.href);
  return updatedUrl.pathname + srcUrl.search;
}

function renderRating(
  context: ICoreContext,
  typeName: string,
  id: string,
  avgRating?: number,
  totalRatings?: number,
  ariaLabel?: string,
): JSX.Element | null {
  if (!avgRating) {
    return null;
  }

  const numRatings = (totalRatings && totalRatings.toString()) || undefined;
  const ratingAriaLabel = getRatingAriaLabel(avgRating, ariaLabel);

  return (
    <RatingComponent
      context={context}
      id={id}
      typeName={typeName}
      avgRating={avgRating}
      ratingCount={numRatings}
      readOnly
      ariaLabel={ratingAriaLabel}
      data={{}}
    />
  );
}

// function renderPrice(
//   context: ICoreContext,
//   typeName: string,
//   id: string,
//   basePrice?: number,
//   adjustedPrice?: number,
//   savingsText?: string,
//   freePriceText?: string,
//   originalPriceText?: string,
//   currentPriceText?: string,
//   currencyCode?:any
// ): JSX.Element | null {
//   const price: ProductPrice = {
//     BasePrice: basePrice,
//     AdjustedPrice: adjustedPrice,
//     CustomerContextualPrice: adjustedPrice,
//   };
//   return (
//     <PriceComponent
//       context={context}
//       id={id}
//       typeName={typeName}
//       data={{ price }}
//       savingsText={savingsText}
//       freePriceText={freePriceText}
//       originalPriceText={originalPriceText}
//       currencyCode={currencyCode}
//     />
//   );
// }

function renderProductPlacementImage(
  imageSettings?: IImageSettings,
  gridSettings?: IGridSettings,
  imageUrl?: string,
  altText?: string,
): JSX.Element | null {
  const defaultImageSettings: IImageSettings = {
    viewports: {
      xs: {q: `w=300&h=300&m=6`, w: 0, h: 0},
      lg: {q: `w=300&h=300&m=6`, w: 0, h: 0},
      xl: {q: `w=300&h=300&m=6`, w: 0, h: 0},
    },
    lazyload: true,
    cropFocalRegion: true,
  };
  if (!imageUrl || !gridSettings || !imageSettings) {
    return null;
  }
  const img: IImageData = {
    src: imageUrl,
    altText: altText ? altText : "",
  };
  const imageProps = {
    gridSettings,
    imageSettings: imageSettings || defaultImageSettings,
  };
  imageProps.imageSettings.cropFocalRegion = true;
  return <Image {...img} {...imageProps} loadFailureBehavior="empty" />;
}

export const ProductComponentPremium: React.FunctionComponent<any> = msdyn365Commerce.createComponentOverride<
  any
>("Product", {component: ProductCard, ...PriceComponentActions});

export default ProductComponentPremium;
